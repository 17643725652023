<template>
  <div class="dashboard-wrapper">
    <dashboard-page-header :user-data="getUserData" />
    <statistics
      :tasks-data="tasks"
      :current-tasks-arrow="currentTasksArrow"
      :current-tasks-count="currentTasksCount"
      class="statistics"
    />
    <dashboard-tasks-table
      :tasks-data="tasks"
      :tasks-table-is-loading="tasksTableIsLoading"
      class="tasks"
      @rerender="getData"
    />
    <!-- <pre>{{ tasks }}</pre> -->
    <div class="dashboard-charts">
      <div class="aligner">
        <dashboard-stop-states />
        <dashboard-average-task-time />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DashboardPageHeader from './PageHeader.vue'
import Statistics from './Statistics.vue'
import DashboardTasksTable from './DashboardTasksTable/index.vue'
import DashboardStopStates from './DashboardCharts/DashboardStopStates.vue'
import DashboardAverageTaskTime from './DashboardCharts/DashboardAverageTaskTime.vue'
import { orderBy } from 'lodash'

export default {
  name: 'DashboardComponent',
  components: {
    DashboardPageHeader,
    Statistics,
    DashboardTasksTable,
    DashboardStopStates,
    DashboardAverageTaskTime
  },
  data () {
    return {
      tasks: [],
      currentTasksArrow: null,
      currentTasksCount: null,
      interval: null,
      tasksTableIsLoading: false
    }
  },
  computed: {
    ...mapGetters('User', ['getUserData'])
  },
  created () {
    this.getData()
    this.interval = setInterval(async () => {
      const old = new Set(this.tasks?.map(e => e.id) ?? [])
      await this.getData()
      const newIds = this.tasks?.map(e => e.id) ?? []
      if (!newIds.every(id => old.has(id))) {
        // const audio = new Audio(Fanfare)
        // audio.play()
        // + TASK
        this.currentTasksArrow = true
      } else if (newIds.every(id => old.has(id))) {
        // SAME STATE
        this.currentTasksArrow = null
      } else {
        // - TASK
        this.currentTasksArrow = false
      }
    }, ['ADMIN', 'HEAD_MAINTAINER'].includes(this.getUserData.role) ? 60000 : 120000)
  },
  beforeUnmount () {
    clearInterval(this.interval)
    this.interval = null
  },
  methods: {
    async getData () {
      try {
        this.tasksTableIsLoading = true
        const strByType = {
          BUILDING_EQUIPMENT: 'Vybavnie budovy',
          WORKSHOP: 'Práca na dielni'
        }
        const userId = this.getUserData.id
        const response = await this.$axios.get('/machine-errors/?select=*,mainTask(user(*)),tasks(user(*)),machine(*),errorCategory(name),author(*),neededMaintainers,canBeClaimed&filter.finishedAt.isnull=true')
        console.log(response)
        // console.log(orderBy(
        //   [{ a: false }, { a: true }],
        //   [
        //     'a'
        //   ],
        //   ['asc']
        // ))
        this.tasks = orderBy(
          response.data,
          [
            e => e.mainTask?.userId !== userId,
            'canBeClaimed',
            e => e.machine.priority
          ],
          ['asc', 'desc', 'asc']
        ).map(task => {
          const shouldClean = ['BUILDING_EQUIPMENT', 'WORKSHOP'].includes(task.machine.type)
          let errorStatus
          if (task.mainTask == null) {
            errorStatus = this.$t('dashboard.error-table.table.unassigned')
          } else {
            errorStatus = task.mainTask.user.fullName
            if (task.canBeClaimed) {
              errorStatus = this.$t('dashboard.error-table.table.withHelp') + task.mainTask.user.fullName
            }
            if (task.mainTask.activePause) {
              errorStatus = this.$t('dashboard.error-table.table.paused')
            }
          }
          return {
            id: task.id,
            category: this.$t('dashboard.error-table.table.categories.machines'),
            createdAt: task.createdAt,
            name: shouldClean ? task.machine.name : task.errorCategory.name,
            machine: strByType[task.machine.type] ?? task.machine.name,
            hall: task.machine.hall.name,
            status: shouldClean
              ? ''
              : task.machineStatus,
            claim: task.canBeClaimed ? this.$t('dashboard.error-table.table.canBeClaimed') : task.author.fullName,
            description: task.description,
            errorStatus,
            maintainers: !!(task.neededMaintainers > 1 && task.canBeClaimed),
            highlight: !task.canBeClaimed && task.tasks?.some?.(task => task.userId === userId)
          }
        })

        // len nepriradene ulohy
        // this.currentTasksCount = this.tasks.filter(item => {
        //   if (item.errorStatus === this.$t('dashboard.error-table.table.unassigned')) {
        //     return true
        //   } return false
        // }).length

        // vsetky ulohy ktore nie su ukoncene
        this.currentTasksCount = this.tasks.length

        this.tasksTableIsLoading = false
      } catch (err) {
        console.error(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div.dashboard-wrapper {
  width: 100%;
  padding-top: 72px; // because of header

  .statistics {
    margin-top: -48px;
  }

  .tasks {
    margin-top: 24px;
  }

  div.dashboard-charts {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 167px 167px 167px;
    margin-top: 24px;

    div.aligner {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 32px;
      width: 100%;
      max-width: 1108px;
    }
  }
}

@media screen and (max-width: 1130px) {
  div.dashboard-wrapper {
    div.dashboard-charts {
      div.aligner {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-row-gap: 24px;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  div.dashboard-wrapper {
    padding: 64px 0 100px 0;

    .statistics {
      margin-top: 0;
    }

    .tasks {
      margin-top: 16px;
    }

    div.dashboard-charts {
      margin-top: 16px;
      padding: 0;
    }
  }
}
</style>
