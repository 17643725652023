<template>
  <div class="dashboard-average-task-time">
    <loading-spinner v-if="isLoading" />
    <bar-chart
      v-else
      :chart-data="chartData"
      :chart-options="chartOptions"
    />
  </div>
</template>

<script>
import LoadingSpinner from '@/components/GlobalComponents/LoadingSpinner'
import BarChart from '@/components/GlobalComponents/Charts/BarChart'
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardAverageTaskTime',
  components: {
    LoadingSpinner,
    BarChart
  },
  data () {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: '#FD6412',
            barThickness: 25, // sirka farebnych stlpcov v px
            data: []
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: { // nazov grafu
            display: true,
            align: 'start',
            padding: {
              top: 0,
              bottom: 34
            },
            color: '#000000',
            font: {
              family: 'Helvetica Neue LT Pro',
              size: 20,
              weight: '500',
              lineHeight: '32px'
            },
            text: this.$t('dashboard.charts.average-task-time.name')
          },
          legend: { // legenda
            display: false // hide legend on top
          },
          tooltip: {
            titleFont: {
              family: 'Helvetica Neue LT Pro'
            },
            bodyFont: {
              family: 'Helvetica Neue LT Pro'
            }
          }
        },
        scales: {
          x: {
            grid: { // x axis grid
              display: false
            },
            ticks: { // x axis labels
              color: '#7D7D7D',
              font: {
                family: 'Helvetica Neue LT Pro',
                size: 12,
                weight: '400',
                lineHeight: '16px'
              }
            }
          },
          y: {
            grid: { // y axis grid
              color: 'rgba(228, 228, 228, 0.2)',
              borderColor: ''
            },
            ticks: { // y axis labels
              color: '#7D7D7D',
              font: {
                family: 'Helvetica Neue LT Pro',
                size: 12,
                weight: '400',
                lineHeight: '16px'
              }
            }
          }
        }
      },
      interval: null,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters('User', ['getUserData'])
  },
  created () {
    this.getAverageTaskTimeData()
    this.interval = setInterval(async () => {
      await Promise.allSettled([this.resetData(), this.getAverageTaskTimeData()])
    }, ['ADMIN', 'HEAD_MAINTAINER'].includes(this.getUserData.role) ? 60000 : 120000)
  },
  beforeUnmount () {
    clearInterval(this.interval)
    this.interval = null
  },
  methods: {
    async getAverageTaskTimeData () {
      try {
        this.isLoading = true
        const { data } = await this.$axios.get('/machine-errors/?select=*,machine(*),errorCategory(name,description),mainTask(*,user,activePause),tasks(*,user,activePause)&filter.finishedAt.isnotnull=true,filter.startedAt.isnotnull=true')
        // uprava objectu => vytiahnutie datumov, uprava na milisekundy bez casu, vypocet trvania ulohy
        const tasksHistoryData = data.map(x => ({
          createdAt: new Date(x.createdAt).setHours(0, 0, 0, 0),
          finishedAt: new Date(x.finishedAt).setHours(0, 0, 0, 0),
          duration: ((new Date(x.finishedAt).getTime() - new Date(x.startedAt).getTime()) / 1000) / 60,
          tasks: x.tasks.map(y => ({
            pauses: y.pauses.map(z => ({
              duration: (((new Date(z.finishAt).getTime() - new Date(z.startAt).getTime()) / 1000) / 60)
            }))
          }))
        }))

        // zratanie pauz v kazdom tasku
        const countingPauseTime = tasksHistoryData.map(a => ({
          createdAt: a.createdAt,
          finishedAt: a.finishedAt,
          duration: a.duration,
          tasks: a.tasks.map(b => ({
            taskPauseTotalTime: b.pauses.map(c => c.duration).reduce((result, item) => result + item, 0)
          }))
        }))

        // scitanie pauz vsetkych taskov
        const countingAllTasksPauseTime = countingPauseTime.map(a => ({
          createdAt: a.createdAt,
          finishedAt: a.finishedAt,
          duration: a.duration,
          totalPauseTime: a.tasks.map(b => b.taskPauseTotalTime).reduce((result, item) => result + item, 0)
        }))

        // vypocitanie skutocneho casu trvania tasku (celkove trvanie - trvanie vsetkych pauz dokopy)
        const finalTimes = countingAllTasksPauseTime.map(a => ({
          createdAt: a.createdAt,
          finishedAt: a.finishedAt,
          duration: a.duration,
          totalPauseTime: a.totalPauseTime,
          taskTime: a.duration - a.totalPauseTime
        }))
        // this.tasksHistoryData = finalTimes

        // get last week dates
        const todayDate = new Date()
        const today0 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate())
        const today1 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 1)
        const today2 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 2)
        const today3 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 3)
        const today4 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 4)
        const today5 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 5)
        const today6 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 6)

        // get days name
        const weekday = [
          this.$t('dashboard.pageHeader.days.sunday'),
          this.$t('dashboard.pageHeader.days.monday'),
          this.$t('dashboard.pageHeader.days.tuesday'),
          this.$t('dashboard.pageHeader.days.wednesday'),
          this.$t('dashboard.pageHeader.days.thursday'),
          this.$t('dashboard.pageHeader.days.friday'),
          this.$t('dashboard.pageHeader.days.saturday')
        ]
        const todayName = weekday[today0.getDay()]
        const todayName1 = weekday[today1.getDay()]
        const todayName2 = weekday[today2.getDay()]
        const todayName3 = weekday[today3.getDay()]
        const todayName4 = weekday[today4.getDay()]
        const todayName5 = weekday[today5.getDay()]
        const todayName6 = weekday[today6.getDay()]
        // add days to chart data labels, (only first 2 letters with substring)
        this.chartData.labels.unshift(
        //   todayName.substring(0, 2),
        //   todayName1.substring(0, 2),
        //   todayName2.substring(0, 2),
        //   todayName3.substring(0, 2),
        //   todayName4.substring(0, 2),
        //   todayName5.substring(0, 2),
        //   todayName6.substring(0, 2)
          todayName,
          todayName1,
          todayName2,
          todayName3,
          todayName4,
          todayName5,
          todayName6
        )
        // )

        // set dates to 0:00:00 and miliseconds
        const today0ms = new Date(today0).setHours(0, 0, 0, 0)
        const today1ms = new Date(today1).setHours(0, 0, 0, 0)
        const today2ms = new Date(today2).setHours(0, 0, 0, 0)
        const today3ms = new Date(today3).setHours(0, 0, 0, 0)
        const today4ms = new Date(today4).setHours(0, 0, 0, 0)
        const today5ms = new Date(today5).setHours(0, 0, 0, 0)
        const today6ms = new Date(today6).setHours(0, 0, 0, 0)

        // rozdelenie taskov podla dni
        const tasksToday0 = []
        const tasksToday1 = []
        const tasksToday2 = []
        const tasksToday3 = []
        const tasksToday4 = []
        const tasksToday5 = []
        const tasksToday6 = []
        for (let i = 0; i < finalTimes.length; i++) {
          if (finalTimes[i].finishedAt === today0ms) {
            tasksToday0.push(finalTimes[i].taskTime)
          } else if (finalTimes[i].finishedAt === today1ms) {
            tasksToday1.push(finalTimes[i].taskTime)
          } else if (finalTimes[i].finishedAt === today2ms) {
            tasksToday2.push(finalTimes[i].taskTime)
          } else if (finalTimes[i].finishedAt === today3ms) {
            tasksToday3.push(finalTimes[i].taskTime)
          } else if (finalTimes[i].finishedAt === today4ms) {
            tasksToday4.push(finalTimes[i].taskTime)
          } else if (finalTimes[i].finishedAt === today5ms) {
            tasksToday5.push(finalTimes[i].taskTime)
          } else if (finalTimes[i].finishedAt === today6ms) {
            tasksToday6.push(finalTimes[i].taskTime)
          }
        }

        // console.log(tasksToday0)
        // console.log(tasksToday1)
        // console.log(tasksToday2)
        // console.log(tasksToday3)
        // console.log(tasksToday4)
        // console.log(tasksToday5)
        // console.log(tasksToday6)

        // vypocet priemernych casov za kazdy den
        const countToday0 = Math.round(tasksToday0.reduce((a, b) => a + b, 0) / tasksToday0.length)
        const countToday1 = Math.round(tasksToday1.reduce((a, b) => a + b, 0) / tasksToday1.length)
        const countToday2 = Math.round(tasksToday2.reduce((a, b) => a + b, 0) / tasksToday2.length)
        const countToday3 = Math.round(tasksToday3.reduce((a, b) => a + b, 0) / tasksToday3.length)
        const countToday4 = Math.round(tasksToday4.reduce((a, b) => a + b, 0) / tasksToday4.length)
        const countToday5 = Math.round(tasksToday5.reduce((a, b) => a + b, 0) / tasksToday5.length)
        const countToday6 = Math.round(tasksToday6.reduce((a, b) => a + b, 0) / tasksToday6.length)
        // console.log(countToday0)
        // console.log(countToday1)
        // console.log(countToday2)
        // console.log(countToday3)
        // console.log(countToday4)
        // console.log(countToday5)
        // console.log(countToday6)

        this.chartData.datasets[0].data.unshift(
          countToday0,
          countToday1,
          countToday2,
          countToday3,
          countToday4,
          countToday5,
          countToday6
        )
        this.isLoading = false
      } catch (err) {
        console.error(err)
      }
    },
    resetData () {
      this.chartData.labels = []
      this.chartData.datasets[0].data = []
    }
  }
}
</script>

<style lang="scss" scoped>
div.dashboard-average-task-time {
  background-color: $primary-white;
  padding: 32px;
  width: 100%;
  max-width: 538px;
  min-width: 375px;
}

@media screen and (max-width: 1130px) {
  div.dashboard-average-task-time {
    max-width: 100%;
  }
}
</style>
