<template>
  <div class="dashboard-stop-states">
    <loading-spinner v-if="isLoading" />
    <bar-chart
      v-else
      :chart-data="chartData"
      :chart-options="chartOptions"
    />
  </div>
</template>

<script>
import LoadingSpinner from '@/components/GlobalComponents/LoadingSpinner'
import BarChart from '@/components/GlobalComponents/Charts/BarChart'
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardStopStates',
  components: {
    LoadingSpinner,
    BarChart
  },
  data () {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: '#FD6412',
            barThickness: 25, // sirka farebnych stlpcov v px
            data: []
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: { // nazov grafu
            display: true,
            align: 'start',
            padding: {
              top: 0,
              bottom: 34
            },
            color: '#000000',
            font: {
              family: 'Helvetica Neue LT Pro',
              size: 20,
              weight: '500',
              lineHeight: '32px'
            },
            text: this.$t('dashboard.charts.stop-states.name')
          },
          legend: { // legenda
            display: false // hide legend on top
          },
          tooltip: {
            titleFont: {
              family: 'Helvetica Neue LT Pro'
            },
            bodyFont: {
              family: 'Helvetica Neue LT Pro'
            }
          }
        },
        scales: {
          x: {
            grid: { // x axis grid
              display: false
            },
            ticks: { // x axis labels
              color: '#7D7D7D',
              font: {
                family: 'Helvetica Neue LT Pro',
                size: 12,
                weight: '400',
                lineHeight: '16px'
              }
            }
          },
          y: {
            grid: { // y axis grid
              color: 'rgba(228, 228, 228, 0.2)',
              borderColor: ''
            },
            ticks: { // y axis labels
              color: '#7D7D7D',
              font: {
                family: 'Helvetica Neue LT Pro',
                size: 12,
                weight: '400',
                lineHeight: '16px'
              },
              stepSize: 2 // y axis label increment
            }
          }
        }
      },
      interval: null,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters('User', ['getUserData'])
  },
  created () {
    this.getStopStatesData()
    this.interval = setInterval(async () => {
      await Promise.allSettled([this.resetData(), this.getStopStatesData()])
    }, ['ADMIN', 'HEAD_MAINTAINER'].includes(this.getUserData.role) ? 60000 : 120000)
  },
  beforeUnmount () {
    clearInterval(this.interval)
    this.interval = null
  },
  methods: {
    async getStopStatesData () {
      try {
        this.isLoading = true
        const { data } = await this.$axios.get('/machine-errors/?select=*,mainTask(user(*)),tasks(user(*)),machine(*),errorCategory(name),author(*),neededMaintainers,canBeClaimed')
        const filteredData = []
        // only tasks with machineStatus = 'STOPPED'
        for (let i = 0; i < data.length; i++) {
          if (data[i].machineStatus === 'STOPPED') {
            filteredData.push(data[i])
          }
        }

        // get last week dates
        const todayDate = new Date()
        const today0 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate())
        const today1 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 1)
        const today2 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 2)
        const today3 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 3)
        const today4 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 4)
        const today5 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 5)
        const today6 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 6)

        // get days name
        const weekday = [
          this.$t('dashboard.pageHeader.days.sunday'),
          this.$t('dashboard.pageHeader.days.monday'),
          this.$t('dashboard.pageHeader.days.tuesday'),
          this.$t('dashboard.pageHeader.days.wednesday'),
          this.$t('dashboard.pageHeader.days.thursday'),
          this.$t('dashboard.pageHeader.days.friday'),
          this.$t('dashboard.pageHeader.days.saturday')
        ]
        const todayName = weekday[today0.getDay()]
        const todayName1 = weekday[today1.getDay()]
        const todayName2 = weekday[today2.getDay()]
        const todayName3 = weekday[today3.getDay()]
        const todayName4 = weekday[today4.getDay()]
        const todayName5 = weekday[today5.getDay()]
        const todayName6 = weekday[today6.getDay()]
        // add days to chart data labels, (only first 2 letters with substring)
        this.chartData.labels.unshift(
        //   todayName.substring(0, 2),
        //   todayName1.substring(0, 2),
        //   todayName2.substring(0, 2),
        //   todayName3.substring(0, 2),
        //   todayName4.substring(0, 2),
        //   todayName5.substring(0, 2),
        //   todayName6.substring(0, 2)
          todayName,
          todayName1,
          todayName2,
          todayName3,
          todayName4,
          todayName5,
          todayName6
        )
        // )

        // set time to 0:00:00 and miliseconds
        const filteredDataSetTime = filteredData.map(a => ({
          id: a.id,
          createdAt: new Date(a.createdAt).setHours(0, 0, 0, 0)
        }))

        // set dates to 0:00:00 and miliseconds
        const today0ms = new Date(today0).setHours(0, 0, 0, 0)
        const today1ms = new Date(today1).setHours(0, 0, 0, 0)
        const today2ms = new Date(today2).setHours(0, 0, 0, 0)
        const today3ms = new Date(today3).setHours(0, 0, 0, 0)
        const today4ms = new Date(today4).setHours(0, 0, 0, 0)
        const today5ms = new Date(today5).setHours(0, 0, 0, 0)
        const today6ms = new Date(today6).setHours(0, 0, 0, 0)

        // count tasks each day
        const countToday0 = filteredDataSetTime.filter(obj => {
          if (obj.createdAt === today0ms) {
            return true
          } return false
        }).length
        const countToday1 = filteredDataSetTime.filter(obj => {
          if (obj.createdAt === today1ms) {
            return true
          } return false
        }).length
        const countToday2 = filteredDataSetTime.filter(obj => {
          if (obj.createdAt === today2ms) {
            return true
          } return false
        }).length
        const countToday3 = filteredDataSetTime.filter(obj => {
          if (obj.createdAt === today3ms) {
            return true
          } return false
        }).length
        const countToday4 = filteredDataSetTime.filter(obj => {
          if (obj.createdAt === today4ms) {
            return true
          } return false
        }).length
        const countToday5 = filteredDataSetTime.filter(obj => {
          if (obj.createdAt === today5ms) {
            return true
          } return false
        }).length
        const countToday6 = filteredDataSetTime.filter(obj => {
          if (obj.createdAt === today6ms) {
            return true
          } return false
        }).length

        this.chartData.datasets[0].data.unshift(
          countToday0,
          countToday1,
          countToday2,
          countToday3,
          countToday4,
          countToday5,
          countToday6
        )
        this.isLoading = false
      } catch (err) {
        console.error(err)
      }
    },
    resetData () {
      this.chartData.labels = []
      this.chartData.datasets[0].data = []
    }
  }
}
</script>

<style lang="scss" scoped>
div.dashboard-stop-states {
  background-color: $primary-white;
  padding: 32px;
  width: 100%;
  max-width: 538px;
  min-width: 375px;
}

@media screen and (max-width: 1130px) {
  div.dashboard-stop-states {
    max-width: 100%;
  }
}
</style>
