<template>
  <div class="statistics-container">
    <div class="current-faults">
      <p class="faults-title">
        {{ $t('dashboard.statistics.currentFaults') }}
      </p>
      <loading-spinner
        v-if="isLoading.currentTasksCount"
        :size="60"
      />
      <div
        v-else
        class="faults-row"
      >
        <p class="number">
          {{ currentTasksCount }}
        </p>
        <img
          v-if="currentTasksArrow === false"
          :src="ArrowDownIcon"
          alt="Down"
          class="arrow-icon"
        >
        <img
          v-if="currentTasksArrow === true"
          :src="ArrowUpIcon"
          alt="Up"
          class="arrow-icon"
        >
      </div>
    </div>
    <div class="faults-history">
      <p class="faults-title">
        {{ $t('dashboard.statistics.faultsHistory') }}
      </p>
      <loading-spinner
        v-if="isLoading.tasksHistoryCount"
        :size="60"
      />
      <div
        v-else
        class="faults-row"
      >
        <p class="number">
          {{ tasksHistoryCount }}
        </p>
        <img
          v-if="tasksHistoryArrow === false"
          :src="ArrowDownIcon"
          alt="Down"
          class="arrow-icon"
        >
        <img
          v-if="tasksHistoryArrow === true"
          :src="ArrowUpIcon"
          alt="Up"
          class="arrow-icon"
        >
      </div>
    </div>
    <div class="average-time">
      <p
        v-if="windowWidth > 960"
        class="faults-title"
      >
        {{ $t('dashboard.statistics.averageTime') }}
      </p>
      <p
        v-else
        class="faults-title"
      >
        {{ $t('dashboard.statistics.averageTimeMobile') }}
      </p>
      <loading-spinner
        v-if="isLoading.averageTime"
        :size="60"
      />
      <div
        v-else
        class="faults-row"
      >
        <p class="number">
          {{ averageTime }}
        </p>
        <span class="minutes">
          {{ $t('dashboard.statistics.minutes') }}
        </span>
        <img
          v-if="averageTimeArrow === false"
          :src="ArrowDownIcon"
          alt="Down"
          class="arrow-icon"
        >
        <img
          v-if="averageTimeArrow === true"
          :src="ArrowUpIcon"
          alt="Up"
          class="arrow-icon"
        >
      </div>
    </div>
    <div class="free-users">
      <p class="faults-title">
        {{ $t('dashboard.statistics.freeEmployees') }}
      </p>
      <loading-spinner
        v-if="isLoading.freeUsers"
        :size="60"
      />
      <div
        v-else
        class="faults-row"
      >
        <p class="number">
          {{ freeUsersList.length }}<span class="second-number">/{{ allUsersList.length }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ArrowUpIcon from '@/assets/icons/arrow_up_red.svg'
import ArrowDownIcon from '@/assets/icons/arrow_down_green.svg'
import LoadingSpinner from '@/components/GlobalComponents/LoadingSpinner'

export default {
  name: 'Statistics',
  components: {
    LoadingSpinner
  },
  props: {
    tasksData: {
      type: Array,
      required: true
    },
    currentTasksArrow: {
      type: Boolean
      // required: true
    },
    currentTasksCount: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      ArrowUpIcon,
      ArrowDownIcon,
      windowWidth: 0,
      interval: null,
      tasksHistoryData: {},
      tasksHistoryCount: 0,
      tasksHistoryArrow: null,
      averageTime: null,
      averageTimeArrow: null,
      allUsersList: [],
      freeUsersList: [],
      isLoading: {
        currentTasksCount: false,
        tasksHistoryCount: false,
        averageTime: false,
        freeUsers: false
      }
    }
  },
  computed: {
    ...mapGetters('User', ['getUserData'])
  },
  created () {
    this.getTasksHistoryData()
    this.getAllUsersData()
    this.getFreeUsersData()
    this.interval = setInterval(async () => {
      await Promise.allSettled([this.getTasksHistoryData(), this.getAllUsersData(), this.getFreeUsersData()])
    }, ['ADMIN', 'HEAD_MAINTAINER'].includes(this.getUserData.role) ? 60000 : 120000)
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeUnmount () {
    clearInterval(this.interval)
    this.interval = null
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    async getTasksHistoryData () {
      try {
        this.isLoading.currentTasksCount = true
        this.isLoading.tasksHistoryCount = true
        this.isLoading.averageTime = true
        // const { data } = await this.$axios.get('/machine-errors/?select=*,mainTask(user(*)),tasks(user(*)),machine(*),errorCategory(name),author(*),neededMaintainers,canBeClaimed&filter.finishedAt.isnotnull=true')
        const { data } = await this.$axios.get('/machine-errors/?select=*,machine(*),errorCategory(name,description),mainTask(*,user,activePause),tasks(*,user,activePause)&filter.finishedAt.isnotnull=true')
        // uprava objectu => vytiahnutie datumov, uprava na milisekundy bez casu, vypocet trvania ulohy
        const tasksHistoryData = data.map(x => ({
          createdAt: new Date(x.createdAt).setHours(0, 0, 0, 0),
          finishedAt: new Date(x.finishedAt).setHours(0, 0, 0, 0),
          // milisekundy na minuty
          duration: ((new Date(x.finishedAt).getTime() - new Date(x.createdAt).getTime()) / 1000) / 60,
          // vyratanie trvania vsetkych pauz na minuty
          tasks: x.tasks.map(y => ({
            pauses: y.pauses.map(z => ({
              duration: (((new Date(z.finishAt).getTime() - new Date(z.startAt).getTime()) / 1000) / 60)
            }))
          }))
        }))

        // zratanie pauz v kazdom tasku
        const countingPauseTime = tasksHistoryData.map(a => ({
          createdAt: a.createdAt,
          finishedAt: a.finishedAt,
          duration: a.duration,
          tasks: a.tasks.map(b => ({
            taskPauseTotalTime: b.pauses.map(c => c.duration).reduce((result, item) => result + item, 0)
          }))
        }))

        // scitanie pauz vsetkych taskov
        const countingAllTasksPauseTime = countingPauseTime.map(a => ({
          createdAt: a.createdAt,
          finishedAt: a.finishedAt,
          duration: a.duration,
          totalPauseTime: a.tasks.map(b => b.taskPauseTotalTime).reduce((result, item) => result + item, 0)
        }))

        // vypocitanie skutocneho casu trvania tasku (celkove trvanie - trvanie vsetkych pauz dokopy)
        const finalTimes = countingAllTasksPauseTime.map(a => ({
          createdAt: a.createdAt,
          finishedAt: a.finishedAt,
          duration: a.duration,
          totalPauseTime: a.totalPauseTime,
          taskTime: a.duration - a.totalPauseTime
        }))
        this.tasksHistoryData = finalTimes
        // console.log(this.tasksHistoryData)

        // datumy
        const todayDate = new Date()
        const oneWeekDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 6)
        const previousWeekFirstDay = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 7)
        const previousWeekLastDay = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 13)
        // milisekundy
        const todayTimestamp = todayDate.getTime()
        const oneWeekTimestamp = oneWeekDate.getTime()
        const previousFirstTimestamp = previousWeekFirstDay.getTime()
        const previousLastTimestamp = previousWeekLastDay.getTime()

        // pocet poruch za posledny tyzden
        const timesThisWeek = [] // array trvani taskov za posledny tyzden
        const countThisWeek = this.tasksHistoryData.filter(obj => {
          if (obj.finishedAt <= todayTimestamp && obj.finishedAt >= oneWeekTimestamp) {
            timesThisWeek.push(obj.taskTime)
            return true
          } return false
        }).length
        this.tasksHistoryCount = countThisWeek

        // pocet poruch za predchadzajuci tyzden
        const timesPreviousWeek = [] // array trvani taskov za predosli tyzden
        const countPreviousWeek = this.tasksHistoryData.filter(obj => {
          if (obj.finishedAt <= previousFirstTimestamp && obj.finishedAt >= previousLastTimestamp) {
            timesPreviousWeek.push(obj.taskTime)
            return true
          } return false
        }).length

        if (countThisWeek > countPreviousWeek) {
          this.tasksHistoryArrow = true
        } else if (countThisWeek < countPreviousWeek) {
          this.tasksHistoryArrow = false
        } else {
          this.tasksHistoryArrow = null
        }

        this.isLoading.currentTasksCount = false
        this.isLoading.tasksHistoryCount = false

        // priemerny cas opravy
        // console.log(timesThisWeek)
        // console.log(timesPreviousWeek)
        const averageThisWeek = timesThisWeek.reduce((a, b) => a + b, 0) / timesThisWeek.length
        const averagePreviousWeek = timesPreviousWeek.reduce((a, b) => a + b, 0) / timesPreviousWeek.length
        // console.log(averageThisWeek)
        // console.log(averagePreviousWeek)
        this.averageTime = Math.round(averageThisWeek)

        // ake je priemerny cas NaN - aby sa nezobrazovalo NaN ale cislo 0
        if (isNaN(this.averageTime)) {
          this.averageTime = 0
        }

        if (averageThisWeek < averagePreviousWeek || isNaN(averageThisWeek)) {
          this.averageTimeArrow = false
        } else if (averageThisWeek > averagePreviousWeek || isNaN(averagePreviousWeek)) {
          this.averageTimeArrow = true
        } else {
          this.averageTimeArrow = null
        }

        this.isLoading.averageTime = false
      } catch (err) {
        console.error(err)
      }
    },
    async getAllUsersData () {
      // vsetci udrzbari v DB s rolou 'MAINTAINER'
      try {
        this.isLoading.freeUsers = true
        const { data } = await this.$axios.get('/users/')
        this.allUsersList = data.filter(x => x.role === 'MAINTAINER')
        // console.log(this.allUsersList)
      } catch (err) {
        console.error(err)
      }
    },
    async getFreeUsersData () {
      // vsetci udrzbari ktori nie su priradeny na ulohu
      try {
        const { data } = await this.$axios.get('/users/user-statistics')
        this.freeUsersList = data.filter(x => x.role === 'MAINTAINER')
        // console.log(this.freeUsersList)
        this.isLoading.freeUsers = false
      } catch (err) {
        console.error(err)
      }
    },
    handleResize () {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style lang="scss" scoped>
div.statistics-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 167px;

  div.current-faults,
  div.faults-history,
  div.average-time,
  div.free-users {
    display: flex;
    flex-direction: column;
    background-color: $primary-white;
    height: 156px;
    width: 100%;
    max-width: 276.25px;
    border-left: 1px solid $background-color;
    padding: 32px;

    &:first-child {
      border-left: 0;
    }

    p.faults-title {
      margin-bottom: 8px;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: $primary-grey;
    }

    div.faults-row {
      display: flex;
      align-items: baseline;

      p.number {
        margin: 0;
        font-size: 57px;
        line-height: 60px;
        letter-spacing: 0.5px;

        span.second-number {
          color: $primary-lightgrey;
        }
      }

      span.minutes {
        color: $primary-grey;
        margin: 11px 0 0 12px;
      }

      img.arrow-icon {
        margin-left: 8px;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  div.statistics-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(2, 1fr);
    padding: 0;

    div.current-faults {
      padding: 24px;
      height: 140px;
      max-width: 100%;
      grid-area: 1/1/2/2;
      border-left: 0;
      border-right: 1px solid $background-color;
      border-bottom: 1px solid $background-color;

      p.faults-title {
        letter-spacing: 0.1px;
      }
    }

    div.faults-history {
      padding: 24px;
      height: 140px;
      max-width: 100%;
      grid-area: 1/2/2/3;
      border-left: 0;
      border-bottom: 1px solid $background-color;

      p.faults-title {
        letter-spacing: 0.1px;
      }
    }

    div.average-time {
      padding: 24px;
      height: 140px;
      max-width: 100%;
      grid-area: 2/1/3/2;
      border-left: 0;
      border-right: 1px solid $background-color;

      p.faults-title {
        letter-spacing: 0.1px;
      }
    }

    div.free-users {
      padding: 24px;
      height: 140px;
      max-width: 100%;
      grid-area: 2/2/3/3;
      border-left: 0;

      p.faults-title {
        letter-spacing: 0.1px;
      }
    }
  }
}
</style>
