<template>
  <div class="dashboard-tasks-table">
    <dashboard-tasks-table-component
      :table-data="tasksData"
      :tasks-table-is-loading="tasksTableIsLoading"
      @rerender="rerender"
    />
  </div>
</template>

<script>
import DashboardTasksTableComponent from './DashboardTasksTableComponent'
import downloadCSV from '@/helpers/downloadCSV'

export default {
  name: 'DashboardTasksTable',
  components: {
    DashboardTasksTableComponent
  },
  props: {
    tasksData: {
      type: Array,
      required: true
    },
    tasksTableIsLoading: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    downloadFile () {
      downloadCSV(this.tasksData, 'data.csv')
    },
    rerender () {
      this.$emit('rerender')
    }
  }
}
</script>

<style lang="scss" scoped>
div.dashboard-tasks-table {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 167px 0 167px;
}

@media screen and (max-width: $mobile) {
  div.dashboard-tasks-table {
    padding: 0;
  }
}
</style>
