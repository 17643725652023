<template>
  <div class="page-header">
    <div class="page-header__content">
      <div class="left">
        <p class="date">
          {{ day }}, {{ dayNumber }}. {{ month }}
        </p>
        <h4 class="heading-desktop">
          {{ $t('dashboard.pageHeader.welcome') }},
          {{ userData.firstName }}
        </h4>
      </div>
      <div
        v-if="windowWidth > 960"
        class="right"
      >
        <custom-button @click="$router.push({ name: 'ErrorReport' })">
          <template v-slot:leftIcon>
            <img
              :src="buttonIcon"
              alt="Plus icon"
            >
          </template>
          {{ $t('dashboard.pageHeader.button') }}
        </custom-button>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from '@/components/GlobalComponents/CustomButton.vue'
import buttonIcon from '@/assets/icons/add.svg'

export default {
  name: 'DashboardPageHeader',
  components: {
    CustomButton
  },
  props: {
    userData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      day: '',
      dayNumber: '',
      month: '',
      buttonIcon,
      windowWidth: 0
    }
  },
  mounted () {
    this.day = this.printDay()
    this.dayNumber = this.printDayNumber()
    this.month = this.printMonth()
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.windowWidth = window.innerWidth
    },
    printDay () {
      const weekday = [
        this.$t('dashboard.pageHeader.days.sunday'),
        this.$t('dashboard.pageHeader.days.monday'),
        this.$t('dashboard.pageHeader.days.tuesday'),
        this.$t('dashboard.pageHeader.days.wednesday'),
        this.$t('dashboard.pageHeader.days.thursday'),
        this.$t('dashboard.pageHeader.days.friday'),
        this.$t('dashboard.pageHeader.days.saturday')
      ]
      const d = new Date()
      const day = weekday[d.getDay()]
      return day
    },
    printDayNumber () {
      return new Date().getDate()
    },
    printMonth () {
      const month = [
        this.$t('dashboard.pageHeader.months.january'),
        this.$t('dashboard.pageHeader.months.february'),
        this.$t('dashboard.pageHeader.months.march'),
        this.$t('dashboard.pageHeader.months.april'),
        this.$t('dashboard.pageHeader.months.may'),
        this.$t('dashboard.pageHeader.months.june'),
        this.$t('dashboard.pageHeader.months.july'),
        this.$t('dashboard.pageHeader.months.august'),
        this.$t('dashboard.pageHeader.months.september'),
        this.$t('dashboard.pageHeader.months.october'),
        this.$t('dashboard.pageHeader.months.november'),
        this.$t('dashboard.pageHeader.months.december')
      ]
      const m = new Date()
      const name = month[m.getMonth()]
      return name
    }
  }
}
</script>

<style lang="scss" scoped>
div.page-header {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 225px;
  background-color: $primary-darkgrey;
  padding: 48px 166px;

  div.page-header__content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1108px;
    height: 64px;

    div.left {
      display: flex;
      flex-direction: column;

      p.date {
        opacity: 0.75;
      }

      p.date,
      h4 {
        color: $primary-white;
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  div.page-header {
    height: 96px;
    padding: 24px;

    div.page-header__content {
      height: unset;

      div.left {
        p.date {
          font-size: 14px;
          margin-bottom: 4px;
        }

        h4 {
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.5px;
        }
      }
    }
  }
}
</style>
